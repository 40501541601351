import * as Sentry from '@sentry/nextjs';

const {
  NEXT_PUBLIC_SENTRY_DSN: SENTRY_DSN,
  // eslint-disable-next-line no-undef
} = process.env;

// eslint-disable-next-line no-undef
process.env.SENTRY_DSN = SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
});
